import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    Box,
    Select,
    MenuItem,
    FormControl,
    Tooltip,
    IconButton,
    TextField,
} from "@mui/material";
import {
    FormatBold,
    FormatItalic,
    FormatUnderlined,
} from "@mui/icons-material";
import {
    Editor,
    EditorState,
    RichUtils,
    ContentState,
} from "draft-js";
import "draft-js/dist/Draft.css";

// Color options with their display names and hex values
const COLOR_OPTIONS = [
    { name: 'Black', value: 'black', hex: '#000000' },
    { name: 'White', value: 'white', hex: '#FFFFFF' },
    { name: 'Red', value: 'red', hex: '#FF0000' },
    { name: 'Blue', value: 'blue', hex: '#0000FF' },
    { name: 'Green', value: 'green', hex: '#008000' },
    { name: 'Purple', value: 'purple', hex: '#800080' },
    { name: 'Orange', value: 'orange', hex: '#FFA500' },
    { name: 'Gray', value: 'gray', hex: '#808080' },
    { name: 'Pink', value: 'pink', hex: '#FFC0CB' },
    { name: 'Brown', value: 'brown', hex: '#A52A2A' },
];

// Font size options
const FONT_SIZES = [
    '10px', '12px', '14px', '16px', '18px', '20px', 
    '22px', '24px', '26px', '28px', '32px', '36px'
];

const AiSummaryEditor = ({ open, onClose, onPerform, value }) => {
    // Generate custom style maps dynamically
    const styleMap = {
        ...Object.fromEntries(
            FONT_SIZES.map(size => [`FONTSIZE_${size.replace('px', '')}`, { fontSize: size }])
        ),
        ...Object.fromEntries(
            COLOR_OPTIONS.map(color => [`COLOR_${color.value.toUpperCase()}`, { color: color.value }])
        )
    };

    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    const [inputValue, setInputValue] = useState("");
    const [currentInlineStyle, setCurrentInlineStyle] = useState({
        textColor: "black",
        fontSize: "16px",
        bold: false,
        italic: false,
        underline: false,
    });

    const [typedText, setTypedText] = useState("");

    useEffect(() => {
        if (value) {
            const currentContent = editorState.getCurrentContent().getPlainText();
            const newText = value.slice(typedText.length);

            if (newText) {
                let index = 0;
                const typeEffect = () => {
                    if (index < newText.length) {
                        const updatedText = typedText + newText.slice(0, index + 1);
                        const contentState = ContentState.createFromText(updatedText);
                        setEditorState(EditorState.createWithContent(contentState));
                        index++;
                        setTimeout(typeEffect, 5);
                    } else {
                        setTypedText(value);
                    }
                };
                typeEffect();
            }
        }
    }, [value]);

    const handleEditorChange = (newState) => {
        setEditorState(newState);
    };

    const toggleInlineStyle = (style) => {
        const newState = RichUtils.toggleInlineStyle(editorState, style);
        setEditorState(newState);

        // Update current inline style state
        switch (style) {
            case "BOLD":
                setCurrentInlineStyle((prev) => ({ ...prev, bold: !prev.bold }));
                break;
            case "ITALIC":
                setCurrentInlineStyle((prev) => ({ ...prev, italic: !prev.italic }));
                break;
            case "UNDERLINE":
                setCurrentInlineStyle((prev) => ({ ...prev, underline: !prev.underline }));
                break;
            default:
                break;
        }
    };

    const handleFontSizeChange = (event) => {
        const fontSize = event.target.value;
        const fontSizeStyle = `FONTSIZE_${fontSize.replace('px', '')}`;
        
        // Remove any existing font size styles
        const currentStyles = editorState.getCurrentInlineStyle();
        const newState = FONT_SIZES
            .map(size => `FONTSIZE_${size.replace('px', '')}`)
            .reduce(
                (state, style) => currentStyles.has(style) 
                    ? RichUtils.toggleInlineStyle(state, style) 
                    : state, 
                editorState
            );

        // Apply new font size style
        const finalState = RichUtils.toggleInlineStyle(newState, fontSizeStyle);
        
        setEditorState(finalState);
        setCurrentInlineStyle((prev) => ({ ...prev, fontSize }));
    };

    const handleTextColorChange = (event) => {
        const textColor = event.target.value;
        const colorStyle = `COLOR_${textColor.toUpperCase()}`;
        
        // Remove any existing color styles
        const currentStyles = editorState.getCurrentInlineStyle();
        const newState = COLOR_OPTIONS
            .map(color => `COLOR_${color.value.toUpperCase()}`)
            .reduce(
                (state, style) => currentStyles.has(style) 
                    ? RichUtils.toggleInlineStyle(state, style) 
                    : state, 
                editorState
            );

        // Apply new color style
        const finalState = RichUtils.toggleInlineStyle(newState, colorStyle);
        
        setEditorState(finalState);
        setCurrentInlineStyle((prev) => ({ ...prev, textColor }));
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };
    const handleKeyDown = (event) => {
        if (event.key=='Enter'){
            handlePerform()

        }
    };

    const handlePerform = () => {
        onPerform(inputValue);
        setInputValue("");

    };

    const handleClose = () => {
        setEditorState(EditorState.createEmpty());
        setTypedText('')
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogContent>
                {/* Toolbar */}
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                        borderBottom: "1px solid #ccc",
                        pb: 1,
                        mb: 2,
                        flexWrap: 'wrap',
                    }}
                >
                    {/* Bold */}
                    <Tooltip title="Bold">
                        <IconButton
                            onClick={() => toggleInlineStyle("BOLD")}
                            color={currentInlineStyle.bold ? "primary" : "default"}
                        >
                            <FormatBold />
                        </IconButton>
                    </Tooltip>

                    {/* Italic */}
                    <Tooltip title="Italic">
                        <IconButton
                            onClick={() => toggleInlineStyle("ITALIC")}
                            color={currentInlineStyle.italic ? "primary" : "default"}
                        >
                            <FormatItalic />
                        </IconButton>
                    </Tooltip>

                    {/* Underline */}
                    <Tooltip title="Underline">
                        <IconButton
                            onClick={() => toggleInlineStyle("UNDERLINE")}
                            color={currentInlineStyle.underline ? "primary" : "default"}
                        >
                            <FormatUnderlined />
                        </IconButton>
                    </Tooltip>

                    {/* Font Size */}
                    <FormControl size="small" sx={{ minWidth: 100 }}>
                        <Select
                            value={currentInlineStyle.fontSize}
                            onChange={handleFontSizeChange}
                            displayEmpty
                        >
                            {FONT_SIZES.map((size) => (
                                <MenuItem key={size} value={size}>
                                    {size}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {/* Text Color */}
                    <FormControl size="small" sx={{ minWidth: 150 }}>
                        <Select
                            value={currentInlineStyle.textColor}
                            onChange={handleTextColorChange}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box 
                                        component="span" 
                                        sx={{ 
                                            width: 15, 
                                            height: 15, 
                                            backgroundColor: selected, 
                                            marginRight: 1,
                                            border: '1px solid gray'
                                        }} 
                                    />
                                    {COLOR_OPTIONS.find(c => c.value === selected)?.name}
                                </Box>
                            )}
                        >
                            {COLOR_OPTIONS.map((color) => (
                                <MenuItem key={color.value} value={color.value}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Box 
                                            component="span" 
                                            sx={{ 
                                                width: 15, 
                                                height: 15, 
                                                backgroundColor: color.value, 
                                                marginRight: 1,
                                                border: '1px solid gray'
                                            }} 
                                        />
                                        {color.name}
                                    </Box>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                {/* Editor */}
                <Box
                    sx={{
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        minHeight: "300px",
                        padding: "10px",
                        backgroundColor: "#fff",
                    }}
                >
                    <Editor
                        editorState={editorState}
                        onChange={handleEditorChange}
                        placeholder="Hey, how can i help you?"
                        customStyleMap={styleMap}
                    />
                </Box>

                {/* Input Field */}
                <Box sx={{ mt: 2, display: "flex", alignItems: "center", gap: 1 }}>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Describe your request"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}

                        placeholder="Type your request here..."
                        InputProps={{
                            style: { fontSize: "1rem" },
                        }}
                    />
                    <Button
                        onClick={handlePerform}
                        variant="contained"
                        color="primary"
                        disabled={!inputValue}
                    >
                        SEND
                    </Button>
                </Box>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant="outlined" color="secondary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AiSummaryEditor;