import React, { useState, useEffect } from "react";
import { Avatar, Button, Chip, Divider, Box, Card, CardActions, CardContent, CardHeader, Link, Rating, Typography, Grid, IconButton, Menu, MenuItem, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { API_URL, popupTimer } from "../../consts";
import QrCodeIcon from "@mui/icons-material/QrCode";
import DeleteIcon from '@mui/icons-material/Delete';
import TimelineIcon from '@mui/icons-material/Timeline';
import MenuIcon from "@mui/icons-material/Menu";
import Done from "@mui/icons-material/Done";
import StarIcon from "@mui/icons-material/Star";
import ChatIcon from "@mui/icons-material/Chat";
import ChipList from "../common/ChipList";
import CommentItem from "../common/CommentItem";
import StatisticsDialog from "../common/StatisticsDialog";
import RatingObject from "../RatingObject/RatingObject";
import AddToSpace from "./AddToSpace";
import PathHistoryDialog from "../common/PathHistoryDialog";
import Description from "./Description";
import ItemContent from "./ItemContent"
const HomeCardDialog = ({ open, onClose, data, isFeedback, isBookmark, displayRemoveFromSpace, searhTags, applyMargin, onRateSuccessfully, displayStars, displayStats, onRemoveFromSpaceClick, showRemove, onRemove }) => {
    const navigate = useNavigate();
    const [showComments, setShowComments] = useState(false);
    const [comments, setComments] = useState([]);
    const [showRateModal, setShowRateModal] = useState(false);
    const [ratingsValue, setRatingsValue] = useState(0);
    const [id, setId] = useState("");
    const [hideMore, setHideMore] = useState(true);
    const [openStatsModal, setOpenStatsModal] = useState(false);
    const [rateId, setRateId] = React.useState(null);
    const [openSpaceModal, setOpenSpaceModal] = useState(false);
    // For Dropdown Menu
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [pathDialogOpen, setPathDialog] = React.useState(false);
    const authorized = useSelector((state) => state.authorized);
    const openMenu = Boolean(anchorEl);
    const dispatch = useDispatch();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const [showMoreComments, setShowMoreComments] = useState(10);

    useEffect(() => {
        getComments();

    }, [showMoreComments]);
    useEffect(() => {
        getComments();
        console.log(data.outer_html)

    }, []);
    const getColorForUrl = (url) => {
        const colors = [
            '#ADD8E6', // Light Blue
            '#20B2AA', // Light Sea Green
            '#5F9EA0', // Cadet Blue
            '#00CED1', // Dark Turquoise
            '#7FFFD4', // Aquamarine
            '#4682B4'  // Steel Blue
        ];

        // Extract the first letter after 'www.' in the URL
        const domain = new URL(url).hostname.replace('www.', '');
        const firstLetter = domain[0].toLowerCase();

        // Get an index based on the first letter's character code
        const colorIndex = firstLetter.charCodeAt(0) % colors.length;

        return colors[colorIndex];
    };
    const getComments = () => {
        // https://webapi.datarate.io/rates/asd/marks
        let url =
            API_URL +
            `/rates/${data.id}/marks?start_from=0&end_record=${showMoreComments}`;
        axios
            .get(url)
            .then((res) => {
                setComments(res.data);
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    dispatch({
                        type: "SET_UNAUTHORIZED",
                    });
                    return;
                }
                dispatch({
                    type: "SET_POPUP",
                    payload: {
                        text: err.response.data.error
                            ? err.response.data.error
                            : "Something Went Wrong. Please Try Again Later",
                        type: "error",
                    },
                });
                setTimeout(() => {
                    dispatch({
                        type: "SET_POPUP",
                        payload: {
                            text: "",
                            type: "",
                        },
                    });
                }, popupTimer);
            });
    };

    const handleLike = (v, i) => {
        setComments([...comments.map((m, index) => {
            if (index === i) return { ...m, liked: m.liked + (v ? +1 : -1) };
            return m;
        })]);
    };

    const handleSpaceModalClose = () => {
        setOpenSpaceModal(false);
    };

    const addComment = (i) => {
        setComments([...comments.map((m, index) => {
            if (index === i) return { ...m, comments: m.comments + 1 };
            return m;
        })]);
    };

    const addToBookMarks = () => {
        let url = API_URL + `/bookmarks`;
        if (authorized) {
            axios.post(url, { rated_object_id: data.id }).then((res) => {
                dispatch({
                    type: "SET_POPUP",
                    payload: {
                        text: "Successfully added to your bookmarks",
                        type: "success",
                    },
                });
                setTimeout(() => {
                    dispatch({
                        type: "SET_POPUP",
                        payload: {
                            text: "",
                            type: "",
                        },
                    });
                }, popupTimer);
            }).catch((err) => {
                if (err.response.status === 401) {
                    dispatch({ type: "SET_UNAUTHORIZED" });
                    return;
                }
                dispatch({
                    type: "SET_POPUP",
                    payload: {
                        text: err.response.data.error ? err.response.data.error : "Something Went Wrong. Please Try Again Later",
                        type: "error",
                    },
                });
                setTimeout(() => {
                    dispatch({
                        type: "SET_POPUP",
                        payload: {
                            text: "",
                            type: "",
                        },
                    });
                }, popupTimer);
            });
        } else {
            dispatch({
                type: "SET_POPUP",
                payload: {
                    text: "You must Login to Perform this Action",
                    type: "error",
                },
            });
            setTimeout(() => {
                dispatch({
                    type: "SET_POPUP",
                    payload: {
                        text: "",
                        type: "",
                    },
                });
            }, popupTimer);
        }
    };

    const onRatingClick = () => {
        setOpenStatsModal(true);
    };

    const showSpacePopup = (rateId) => {
        if (authorized) {
            setRateId(rateId);
            setOpenSpaceModal(true);
        } else {
            showUnauthorizedError();
        }
    };

    const showRatePopup = () => {
        if (authorized) {
            setShowRateModal(true);
            setId(data.id);
        } else {
            showUnauthorizedError();
        }
    };

    const showUnauthorizedError = () => {
        dispatch({
            type: "SET_POPUP",
            payload: {
                text: "You must Login to Perform this Action",
                type: "error",
            },
        });
        setTimeout(() => {
            dispatch({
                type: "SET_POPUP",
                payload: {
                    text: "",
                    type: "",
                },
            });
        }, popupTimer);
    };

    const handleStatsDialogClose = () => {
        setOpenStatsModal(false);
    };

    const handleRatingModalClose = () => {
        setShowRateModal(false);
    };

    const formatDateToMinutesOnly = (timestamp) => {
        const date = new Date(timestamp);
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${month}/${day}/${year} ${hours}:${minutes}`;
    };

    const handleClose = (e) => {
        e.stopPropagation()
        e.preventDefault()
        console.log('close')

        // setAnchorEl(null);
        onClose()
    };

    return (
        <Dialog open={open} onClose={handleClose} BackdropProps={{
            style: {
                backgroundColor: "rgba(0, 0, 0, 0.8)", // Semi-transparent dark background
            },
        }}
            PaperProps={{
                style: {
                    width: "90vw", // Full width of the viewport
                    height: "85%", // Adjust height
                    margin: "auto", // Center it
                    borderRadius: 8, // Optional rounded corners
                },
            }}
            sx={{
                "& .MuiDialog-container": {
                    width: "100vw", // Ensure dialog container is full width
                    margin: 0,
                },
                "& .MuiDialog-paper": {
                    width: "100vw", // Explicitly set paper to full viewport width
                    maxWidth: "100vw", // Remove any maximum width constraints
                },
            }}
        >
            <DialogTitle>
                <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap" }}>

                    {data.page_id && <div className="weburl-link">
                        <div style={{ maxWidth: '600px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                            <Link
                                sx={{
                                    display: 'inline-block',
                                    color: '#128d6f',
                                    fontWeight: '700',
                                    textDecorationColor: '#128d6f',
                                    textOverflow: 'ellipsis',
                                }}
                                href={data.page_id.href}
                                target="_blank"
                                title={data.page_id.href}
                            >
                                {data.page_id.href}
                            </Link>
                        </div>

                    </div>}
                    {isFeedback && (
                        <TimelineIcon onClick={() => setPathDialog(true)} style={{ color: '#128d6f', 'cursor': 'pointer' }} />
                    )}
                </Box>
            </DialogTitle>
            <DialogContent>
                <CardContent className="cardContent" sx={{ position: "relative", width: "100%" }}>

                    <Box sx={{ display: "flex", height: "100%" }}>
                        {/* Left Side Placeholder */}
                        <Box
                            sx={{
                                width: "60%", // 70% width for the left side
                                height: "100%", // Full height
                            }}
                        >
                            {/* https://reddit.com/favicon.ico */}
                            {/* {data.page_id} */}
                            {data.image_url && <img src={data.image_url} style={{ width: '70%', height: '80%', borderRadius: '10px' }} />}

                            {!data.image_url && <div style={{
                                width: '100%',
                                minHeight: '100%',
                                maxHeight: '100%',
                                borderRadius: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: getColorForUrl(data.page_id.href),
                                color: '#fff',
                                fontSize: '32px',
                                fontWeight: 'bold',
                                borderRadius: '4px',
                                position: 'relative',
                            }} >
                                {data.page_id?.href
                                    ? <div style={{ fontSize: '60px' }}>{data.page_id.href.split('www.')[1]?.[0]}</div> || data.page_id.href
                                    : ''}
                            </div>}
                            {data.content && <div>
                                <b>Marked content:</b>

                                {data && <div>{data.content}</div>}
                            </div>}

                        </Box>
                        {/* Right Side Content */}
                        <Box sx={{ width: "40%", padding: "16px", background: 'linear-gradient(to right,#e7ebe4, #fcf9f9)', borderRadius: '10px', marginLeft: '10px' }}>
                            <div style={{ marginLeft: '20px' }}>
                                <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", width: "100%", marginTop: '10px' }} className="ratingSection">

                                    <div style={{ cursor: 'pointer' }} onClick={onRatingClick}>
                                        {data.average_stars > 0 && <Rating
                                            style={{ color: "rgba(255, 204, 72, 1)" }}
                                            name="hover-feedback"
                                            value={data.average_stars}
                                            readOnly
                                            emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                        />}
                                    </div>


                                    {data.average_stars > 0 && <Box style={{ cursor: 'pointer', fontSize: "15px", color: "black", fontWeight: "500" }} onClick={onRatingClick} sx={{ ml: 2, display: 'flex', alignItems: 'center' }}>
                                        {data.average_stars}
                                        <span style={{ fontSize: "15px", color: "grey", fontWeight: "500" }}>
                                            / 5{" "}
                                        </span>
                                    </Box>}




                                    <Button sx={{ minWidth: "24px", borderRadius: "100%", padding: "4px", marginLeft: "auto" }} className="" href={data.page_id && data.page_id.href} target="_blank">
                                    </Button>
                                    {isFeedback && data.resolved && (
                                        <div title={'The customer received an answer'}>
                                            <Done className="iconColor done-feedback-sign" color="primary" />
                                        </div>
                                    )}
                                </Box>
                                {data.price && <div style={{ marginBottom: '10px', marginTop: '10px' }}><b>Price</b></div>}

                                {data.price && <Box
                                >
                                    <div
                                        style={{ fontStyle: 'italic', borderRadius: '5px', maxWidth: '120px', opacity: 0.8 }}
                                    >
                                        {(data.price && data.currency && data.currency !== 'unknown') ? <b>{data.price}{data.currency}</b>
                                            : <b>{data.price_label}</b>}
                                    </div>
                                </Box>}
                                {
                                    data.comment && <div style={{ marginBottom: '10px', marginTop: '10px' }}><b>Summary</b></div>

                                }

                                <div style={{
                                    border: '1px solid #e0e0e0',
                                    borderRadius: '5px',
                                    width: '400px',
                                    height: '120px',
                                    padding: '10px',
                                    marginBottom: '20px'
                                }}>


                                    {data && <div>{data.comment}</div>}
                                </div>
                                {data.tags.length > 0 && <div>
                                    <div style={{ marginBottom: '10px' }}><b>Tags</b></div>
                                    <ChipList list={data.tags} highlightList={searhTags} />
                                </div>}
                            </div>
                        </Box>
                        {/* todo add here the comments section, after comment on comment its working */}
                        {/* <>
                            <div className="paddingStyle" style={{ marginTop: '50px' }}>
                                <div className="commentHeader">
                                    {!isFeedback && <h3>Comments</h3>}
                                </div>
                            </div>
                            <Box sx={{ height: "250px", overflowY: "auto", marginLeft: '50px' }}>
                                {comments.map((m, i) => (
                                    <CommentItem data={m} key={i} isFeedback={true} parentId={data.id} handleLike={(v) => handleLike(v, i)} addComment={() => addComment(i)} />
                                ))}
                                <Box sx={{ justifyContent: "center", display: "flex" }}>
                                    {!isFeedback && comments && comments.length > 10 && (
                                        <Link onClick={() => setShowMoreComments(showMoreComments + 10)} sx={{ cursor: "pointer", color: "#128d6f", fontWeight: "700", textDecorationColor: "#128d6f", marginRight: showMoreComments > 10 ? "1rem" : "0px" }}>
                                            Show More
                                        </Link>
                                    )}
                                    {showMoreComments > 10 ? (
                                        <Link onClick={() => setShowMoreComments(showMoreComments - 10)} sx={{ cursor: "pointer", color: "#128d6f", fontWeight: "700", textDecorationColor: "#128d6f" }}>
                                            Show Less
                                        </Link>
                                    ) : null}
                                </Box>
                            </Box>
                        </> */}
                    </Box>

                </CardContent>

                <>
                    <CardActions disableSpacing sx={{ flexWrap: "wrap" }}>

                        {isFeedback && (
                            <Button sx={{ width: "76px", height: "28px", textAlign: "center", color: "white", fontWeight: "500", backgroundColor: "#128d6f", borderRadius: "50px", marginLeft: "20px" }} className="button-rate" onClick={() => showRatePopup()}>
                                Replay
                            </Button>
                        )}

                    </CardActions>






                </>

                <PathHistoryDialog open={pathDialogOpen} onClose={() => setPathDialog(false)} urls={data.last_urls ? data.last_urls : []} />
                <RatingObject ratings={ratingsValue} open={showRateModal} isFeedback={true} handleClose={handleRatingModalClose} id={id} onRateSuccessfully={onRateSuccessfully} />
                {openSpaceModal && (
                    <AddToSpace open={openSpaceModal} rateId={rateId} handleClose={handleSpaceModalClose} authorized={authorized} />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default HomeCardDialog;
