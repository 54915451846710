import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import { useNavigate } from "react-router";
import { useLocation } from "react-router";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { ListItemButton, IconButton } from "@mui/material";
import { Toolbar } from "@mui/material";
import StarOutline from "@mui/icons-material/StarOutline";
import AddIcon from "@mui/icons-material/Add";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import MenuIcon from "@mui/icons-material/Menu"; // Import MenuIcon

import { useSelector, useDispatch } from "react-redux";
import { API_URL } from "../../consts";
import axios from "axios";
import CreateSpace from "../Space/CreateSpace";
import SplitscreenOutlined from "@mui/icons-material/SplitscreenOutlined";
import { useSidebarEvent } from '../Sidebar/SidebarEventContext';
import Tooltip from '@mui/material/Tooltip';

const drawerWidth = 300;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const MiniSidebar = ({ setProfile, animation }) => {
  const dontMinimizeFromLocalStorage = localStorage.getItem("dontMinimize");
  const theme = useTheme();
  const [open, setOpen] = React.useState(
    dontMinimizeFromLocalStorage === "true"
  );
  const [isCreateSpaceOpen, setIsCreateSpaceOpen] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [spaces, setSpaces] = React.useState([]);
  const [dontMinimize, setDontMinimize] = React.useState(
    dontMinimizeFromLocalStorage === "true"
  );

  const sidebarItems = [
    {
      title: "Community",
      icon: (
        <GroupsOutlinedIcon sx={{ fontSize: 20, color: "rgba(0, 0, 0, 0.6)" }} />
      ),
      path: "/community",
    },
    {
      title: "My Reviews",
      icon: (
        <StarOutline sx={{ fontSize: 20, color: "rgba(0, 0, 0, 0.6)" }} />
      ),
      path: "/my-reviews",
    },
    {
      title: "My Spaces",
      icon: (
        <SplitscreenOutlined
          sx={{ fontSize: 20, color: "rgba(0, 0, 0, 0.6)" }}
        />
      ),
    },
  ];

  const sidebarItemsEnd = [];
  const token = useSelector((state) => state.token);

  React.useEffect(() => {
    if (token) {
      getSpaces();
    }
  }, [token]);

  const dispatch = useDispatch();

  const performGetSpaces = useSelector((state) => state.performGetSpaces);

  React.useEffect(() => {
    if (performGetSpaces) {
      getSpaces();
      dispatch({
        type: "GET_SPACES",
        payload: {
          performGetSpaces: false,
        },
      });
    }
  }, [performGetSpaces, dispatch]);

  const getSpaces = () => {
    let url = API_URL + `/spaces`;
    axios
      .get(url)
      .then((res) => {
        setSpaces(res.data.spaces);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleChangeSwitch = () => {
    const newDontMinimize = !dontMinimize;
    setDontMinimize(newDontMinimize);
    localStorage.setItem("dontMinimize", newDontMinimize);
    if (!newDontMinimize) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  // Ref for the scrollable container
  const spacesContainerRef = React.useRef(null);

  // Function to scroll down the spaces list
  const scrollSpacesDown = () => {
    if (spacesContainerRef.current) {
      spacesContainerRef.current.scrollBy({
        top: 100,
        behavior: "smooth",
      });
    }
  };

  const handleDeleteSpace = (e, space) => {
    e.preventDefault()
    e.stopPropagation()
    // triggerEvent('deleteSpace', { spaceId: space.id })
    dispatch({
      type: "SET_DELETE_SPACE_DIALOG",
      payload: {
        deleteSpaceDialogOpen: true,
      },
    });
    console.log('cliked delete')
  }
  const handleCollaboratorsOpen = (e, space) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch({
      type: "SET_COLLABORATORS_DIALOG_OPEN",
      payload: {
        collobartorsDialogOpen: true,
      },
    });
  }

  return (
    <Drawer
      variant="permanent"
      transitionDuration={3000}
      open={open}
      sx={{
        display: { xs: "none", sm: "block" },
      }}
    >
      <Toolbar sx={{ marginTop: "16px" }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
        onMouseEnter={() => (!animation && !dontMinimize ? setOpen(true) : null)}
        onMouseLeave={() => (!dontMinimize ? setOpen(false) : null)}
      >
        <List>
          {/* Menu Icon List Item */}
          <ListItemButton
            sx={{
              minHeight: 36,
              justifyContent: open ? "initial" : "center",
              px: 3,
            }}
            onClick={handleChangeSwitch}
          >
            <ListItemIcon
              sx={{
                minWidth: "20px",
                justifyContent: "center",
                marginRight: "10px",
              }}
            >
              <MenuIcon sx={{ fontSize: 20, color: "rgba(0, 0, 0, 0.6)" }} />
            </ListItemIcon>
            {open && (
              <ListItemText
                disableTypography
                primary={dontMinimize ? "Minimize Sidebar" : "Keep Open"}
                sx={{
                  marginRight: "10px",
                  fontWeight: 300,
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  maxWidth: open ? "300px" : "0px",
                }}
                title={dontMinimize ? "Minimize Sidebar" : "Keep Open"}
              />
            )}
          </ListItemButton>

          {/* First batch of sidebar items */}
          {sidebarItems.map((item) => (
            <ListItemButton
              key={item.title}
              sx={{
                minHeight: 36,
                justifyContent: open ? "initial" : "center",
                px: 3,
                cursor: item.path ? "pointer" : "default",
              }}
              className={
                item.path === location.pathname
                  ? "highlightedSidebar"
                  : "sidebarItem"
              }
              onClick={() => {
                if (item.path) {
                  navigate(item.path);
                  setProfile(false);
                }
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: "20px",
                  justifyContent: "center",
                  marginRight: "10px",
                }}
                className={
                  item.path === location.pathname ? "iconColor" : "sidebarItem"
                }
              >
                {item.icon}
              </ListItemIcon>
              {open && (
                <ListItemText
                  disableTypography
                  className={
                    item.path === location.pathname
                      ? "sidebarItemHigh"
                      : "sidebarItem"
                  }
                  primary={item.title}
                  sx={{
                    marginRight: "10px",
                    fontWeight: 300,
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    maxWidth: open ? "300px" : "0px",
                  }}
                  title={item.title}
                />
              )}
              {item.title === "My Spaces" && open && (
                <ListItemIcon
                  sx={{
                    minWidth: "20px",
                    marginLeft: "auto",
                    justifyContent: "center",
                  }}
                  onClick={() => setIsCreateSpaceOpen(true)}
                >
                  <Tooltip title="Add space" arrow>

                    <AddIcon
                      sx={{
                        fontSize: 20,
                        color: "rgba(0, 0, 0, 0.6)",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </ListItemIcon>
              )}
            </ListItemButton>
          ))}
        </List>

        {/* Scrollable container for spaces with custom scrollbar */}
        {open && (
          <Box
            sx={{
              height: "200px",
              overflowY: "auto",
              position: "relative",
              // Custom scrollbar styles
              "&::-webkit-scrollbar": {
                width: "6px",
                "-webkit-appearance": "none",
              },
              "&::-webkit-scrollbar-track": {
                background: "transparent",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(0, 0, 0, 0.3)",
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              "&::-webkit-scrollbar-button": {
                display: "none",
                width: 0,
                height: 0,
              },
              // For Firefox
              scrollbarWidth: "thin",
              scrollbarColor: "rgba(0, 0, 0, 0.3) transparent",
            }}
            ref={spacesContainerRef}
          >
            <List>
              {spaces.map((space) => (
                <ListItemButton
                  key={space.id}
                  sx={{
                    justifyContent: open ? "initial" : "center",
                    px: 8,
                  }}
                  className={
                    location.pathname.includes(`/spaces/${space.id}`)
                      ? "highlightedSidebar"
                      : "sidebarItem"
                  }
                  onClick={() => {
                    navigate(`/spaces/${space.id}`);
                    setProfile(false);
                  }}
                >
                  {open && (
                    <ListItemText
                      disableTypography
                      className={
                        location.pathname.includes(`/spaces/${space.id}`)
                          ? "sidebarItemHigh"
                          : "sidebarItem"
                      }
                      primary={space.name}
                      sx={{
                        opacity: open ? 1 : 0,
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: open ? "300px" : "0px",
                      }}
                      title={space.name}
                    />

                  )}
                  {location.pathname.includes(`/spaces/${space.id}`)
                    && <div style={{ paddingLeft: '20px', display: 'flex', gap: '10px', alignItems: 'center' }}>
                      <div>
                        <Tooltip title="Delete this space" arrow>
                          <DeleteOutlineIcon style={{ fontSize: 20, color: "rgba(0, 0, 0, 0.6)" }}
                            title="Delete this space"

                            onClick={(e) => handleDeleteSpace(e, space)} />
                        </Tooltip>
                      </div>
                      <div>
                        <Tooltip title="Add collaborator" arrow>
                          <GroupsOutlinedIcon style={{ fontSize: 20, color: "rgba(0, 0, 0, 0.6)" }} onClick={(e) => handleCollaboratorsOpen(e, space)} />
                        </Tooltip>
                      </div>
                    </div>
                  }



                </ListItemButton>
              ))}
            </List>
          </Box>
        )}

        <List>
          {/* Last batch of sidebar items */}
          {sidebarItemsEnd.map((item) => (
            <ListItemButton
              key={item.title}
              sx={{
                minHeight: 36,
                justifyContent: open ? "initial" : "center",
                px: 3,
                cursor: item.path ? "pointer" : "default",
              }}
              className={
                item.path === location.pathname
                  ? "highlightedSidebar"
                  : "sidebarItem"
              }
              onClick={() => {
                if (item.path) {
                  navigate(item.path);
                  setProfile(false);
                }
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: "20px",
                  justifyContent: "center",
                  marginRight: "10px",
                }}
                className={
                  item.path === location.pathname ? "iconColor" : "sidebarItem"
                }
              >
                {item.icon}
              </ListItemIcon>
              {open && (
                <ListItemText
                  disableTypography
                  className={
                    item.path === location.pathname
                      ? "sidebarItemHigh"
                      : "sidebarItem"
                  }
                  primary={item.title}
                  sx={{
                    marginRight: "10px",
                    fontWeight: 300,
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    maxWidth: open ? "300px" : "0px",
                  }}
                  title={item.title}
                />
              )}
            </ListItemButton>
          ))}
        </List>
      </Box>

      {isCreateSpaceOpen && (
        <CreateSpace
          open={isCreateSpaceOpen}
          handleClose={() => setIsCreateSpaceOpen(false)}
          onSpaceAdded={() => {
            getSpaces();
            setIsCreateSpaceOpen(false);
          }}
        />
      )}
    </Drawer>
  );
};

export default MiniSidebar;
