import React, { useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
    Box,
} from "@mui/material";
import "../style.css";

const AiDialog = ({ open, onClose, onPerform }) => {
    const [inputValue, setInputValue] = useState("");

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handlePerform = () => {
        onPerform(inputValue); // Pass the input value to the onPerform function
        // setInputValue(""); // Reset input after performing the action
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="ai-dialog-title"
            fullWidth
            maxWidth="sm"
            PaperProps={{
                style: {
                    borderRadius: 10,
                    padding: "10px",
                },
            }}
        >
            <DialogTitle id="ai-dialog-title" style={{ textAlign: "center", fontWeight: "bold" }}>
                What task would you like to perform?
            </DialogTitle>
            <Typography
                variant="subtitle2"
                align="center"
                sx={{ mt: 1, color: "text.secondary", fontStyle: "italic" }}
            >
                For example, summarize text or extract specific items
            </Typography>
            <DialogContent>
                <Box sx={{ mt: 2 }}>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Describe your request"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={inputValue}
                        onChange={handleInputChange}
                        placeholder="Hey, how can i help you?"
                        InputProps={{
                            style: { fontSize: "1rem" },
                        }}
                    />
                </Box>
            </DialogContent>
            <Typography
                variant="caption"
                display="block"
                align="center"
                sx={{ color: "text.secondary", mb: 2 }}
            >
                * Actions will be performed on up to the last 30 items
            </Typography>
            <DialogActions>
                <Button onClick={onClose} variant="outlined" color="secondary">
                    Cancel
                </Button>
                <Button
                    onClick={handlePerform}
                    variant="contained"
                    color="primary"
                    disabled={!inputValue.trim()} // Disable if input is empty
                >
                    Perform Task
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AiDialog;
