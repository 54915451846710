import React, { useState } from "react";
import {
    Grid,
    Typography,
    Link,
    Box,
    Button,
    Avatar,
    FormControl,
    Input,
    InputLabel,
    InputAdornment

} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ChipList from "../common/ChipList";
import ai from "../../Assets/ai.png";


import { API_URL } from "../../consts";
import axios from "axios";
import { useSelector } from "react-redux";
import HomeCardItem from "../Home/HomeCardItem";
import Card from "@mui/material/Card";
import AiDialog from "../Ai/AiDialog";
import AiSummaryEditor from "../Ai/AiSummaryEditor";

import social1 from "../../Assets/social_1.svg";
import social2 from "../../Assets/social_2.svg";
import social3 from "../../Assets/social_3.svg";
import social4 from "../../Assets/social_4.svg";
import social5 from "../../Assets/social_5.svg";
import space1 from "../../Assets/space-1.svg";
import trello from "../../Assets/trello-icon.svg";
import asana from "../../Assets/asana-1.svg";
import jira from "../../Assets/jira-3.svg";
import monday from "../../Assets/monday.svg";
import { useNavigate } from "react-router-dom";

import CreateSpace from "./CreateSpace";
import "./style.css";
import { popupTimer } from "../../consts";
import { useDispatch } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import CollaboratorsDialog from "../common/CollaboratorsDialog";
import ConfirmationDialog from "../common/ConfirmationDialog";
import { useParams } from "react-router";

const Space = (props) => {
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(0);
    const [data, setData] = React.useState({ 'bookmarks': [], 'recent_bookmarks': [] });
    const [socialCard, setSocialCard] = React.useState(false);
    const [spacePopup, setSpacePopup] = React.useState(false);
    const token = useSelector((state) => state.token);
    const deleteSpaceDialogOpen = useSelector((state) => state.deleteSpaceDialogOpen);
    const collobartorsDialogOpen = useSelector((state) => state.collobartorsDialogOpen);
    const [spaces, setSpaces] = React.useState([]);
    const [spaceKeywords, setSpaceKeywords] = React.useState([]);
    const [spaceFilterKeywords, setSpaceFilterKeywords] = React.useState([]);
    const [spaceAdded, setSpaceAdded] = React.useState(false);
    const [searchItem, setSearchItem] = React.useState("");
    const [summary, setSummary] = React.useState("")
    const [summaryDialogOpen, setSummaryDialog] = React.useState(false)
    const [inputSearchTags, setInputSearchTags] = React.useState([]);
    const [collaboratorsDialog, setCollaboratorsDialog] = React.useState(false);
    const [collaborators, setCollaborators] = useState([]);
    const [deleteSpaceOpen, setDeleteSpaceOpen] = useState(false);

    const [openAiDialog, setOpenAiDialog] = useState(false);

    const handleSearchTagDelete = (index) => {
        let newInputSearchTags = inputSearchTags.filter((f, i) => i !== index);
        setInputSearchTags(newInputSearchTags);
    };

    const addCollaborator = (collaboratorEmail) => {
        let collaboratorsClone = collaborators.slice()
        let userExist = collaboratorsClone.find((c) => c.email === collaboratorEmail)
        if (userExist) {
            return
        }
        collaboratorsClone.push({ 'name': '', 'email': collaboratorEmail })
        let url = API_URL + `/spaces/${id}/collaborators`;
        let newForm = { "collaborators": [collaboratorEmail] }

        let formData = { ...newForm };
        axios
            .put(url, formData)
            .then((res) => {
                setTimeout(() => {
                    getSpaceCollaborators()

                }, 500)




            }).catch((err) => {
                dispatch({
                    type: "SET_POPUP",
                    payload: {
                        text: err.response.data.error
                            ? err.response.data.error
                            : "Something Went Wrong. Please Try Again Later",
                        type: "error",
                    },
                });
                setTimeout(() => {
                    dispatch({
                        type: "SET_POPUP",
                        payload: {
                            text: "",
                            type: "",
                        },
                    });
                }, popupTimer);
            });

    };
    const deleteCollaborators = (email) => {
        let collaboratorsClone = collaborators.slice()
        let indexInArray = collaboratorsClone.findIndex((c) => c.email === email)
        if (indexInArray < 0) {
            return
        }
        collaboratorsClone.splice(indexInArray, 1)
        setCollaborators(collaborators)

    };
    const onTagAdded = (e) => {

        if (
            e.key === "Enter" && !inputSearchTags.includes(searchItem)
        ) {
            let newInputSearchTags = [...inputSearchTags, searchItem];
            setInputSearchTags(newInputSearchTags);
            setSearchItem("");
        }
    };

    let navigate = useNavigate();


    React.useEffect(() => {
        //get Call will be here
        if (token) {
            getRatesRequest();
        }

    }, [inputSearchTags, token]);
    

    const { id } = useParams();
    React.useEffect(() => {
        if (id && token) {
            getRatesRequest();
            getSpaceCollaborators()

        }

    }, [id, token])
    React.useEffect(() => {
        if (spaceAdded) {
            getSpaces();
            setSpaceAdded(false);
        }
    }, [spaceAdded]);

    const handleClose = () => {
        setSpacePopup(false);
    };


    const getSpaces = () => {
        let url = API_URL + `/spaces`;
        axios
            .get(url)
            .then((res) => {
                setValue(0);
                setSpaces(res.data.spaces);
                if (res.data.spaces.length > 0) {
                    setSpaceKeywords(res.data.spaces[0].keywords);
                    getRatesRequest();
                    setSpaceFilterKeywords(res.data.spaces[0].keywords);
                }
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    dispatch({
                        type: "SET_UNAUTHORIZED",
                    });
                    return;
                }
                dispatch({
                    type: "SET_POPUP",
                    payload: {
                        text: err.response.data.error
                            ? err.response.data.error
                            : "Something Went Wrong. Please Try Again Later",
                        type: "error",
                    },
                });
                setTimeout(() => {
                    dispatch({
                        type: "SET_POPUP",
                        payload: {
                            text: "",
                            type: "",
                        },
                    });
                }, popupTimer);
            });
    };

    const onRateSuccessfully = (id) => {
        let url = API_URL + `/rates/${id}`;
        axios
            .get(url)
            .then((res) => {
                const ratedObj = res.data.rated_object.rate;
                setData(data.map((obj) => (obj.id === id ? (obj = ratedObj) : obj)));
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    dispatch({
                        type: "SET_UNAUTHORIZED",
                    });
                    return;
                }
                dispatch({
                    type: "SET_POPUP",
                    payload: {
                        text: err.response.data.error
                            ? err.response.data.error
                            : "Something Went Wrong. Please Try Again Later",
                        type: "error",
                    },
                });
                setTimeout(() => {
                    dispatch({
                        type: "SET_POPUP",
                        payload: {
                            text: "",
                            type: "",
                        },
                    });
                }, popupTimer);
            });
    };
    const getSpaceIcon = (space) => {
        // trello svg
        if (space['name'].includes('trello')) {
            return trello
        }
        // monday svg
        if (space['name'].includes('monday')) {
            return monday
        }
        if (space['name'].includes('asana')) {
            return asana
        }
        if (space['name'].includes('atlassian')) {
            return jira
        }
        return space1

    };
    const getSpaceKeywords = (label) => {
        setSpaceKeywords(
            spaces.filter((space) => space.name === label)[0].keywords
        );
        setSpaceFilterKeywords(
            spaces.filter((space) => space.name === label)[0].keywords
        );
        setInputSearchTags([]);
        getRatesRequest();
    };
    const getSpaceCollaborators = () => {
        let url =
            API_URL + `/spaces/${id}`;

        axios
            .get(url)
            .then((res) => {
                setCollaborators(res.data.space.collaborators);
                setCollaboratorsDialog(true)

            })
            .catch((err) => {
                if (err.response.status === 401) {
                    dispatch({
                        type: "SET_UNAUTHORIZED",
                    });
                    return;
                }
                dispatch({
                    type: "SET_POPUP",
                    payload: {
                        text: err.response.data.error
                            ? err.response.data.error
                            : "Something Went Wrong. Please Try Again Later",
                        type: "error",
                    },
                });
                setTimeout(() => {
                    dispatch({
                        type: "SET_POPUP",
                        payload: {
                            text: "",
                            type: "",
                        },
                    });
                }, popupTimer);
            });

    };
    const getRatesRequest = () => {
        let paramsBody = {
            tags: inputSearchTags.length > 0 ? inputSearchTags.join(',') : null,
        };
        let url =
            API_URL + `/spaces/${id}/rates`;

        axios
            .get(url, {
                params: paramsBody,
            })
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    dispatch({
                        type: "SET_UNAUTHORIZED",
                    });
                    return;
                }
                dispatch({
                    type: "SET_POPUP",
                    payload: {
                        text: err.response.data.error
                            ? err.response.data.error
                            : "Something Went Wrong. Please Try Again Later",
                        type: "error",
                    },
                });
                setTimeout(() => {
                    dispatch({
                        type: "SET_POPUP",
                        payload: {
                            text: "",
                            type: "",
                        },
                    });
                }, popupTimer);
            });
    };
    const deleteSpace = () => {

        let url = API_URL + `/spaces/${id}`;
        axios
            .delete(url)
            .then((res) => {
                setTimeout(() => {
                    dispatch({
                        type: "GET_SPACES",
                        payload: {
                            performGetSpaces: true
                        },
                    });
                }, 500)

                navigate('/')
                setTimeout(() => {
                    dispatch({
                        type: "SET_POPUP",
                        payload: {
                            text: "",
                            type: "",
                        },
                    });
                }, popupTimer)
                dispatch({
                    type: "SET_POPUP",
                    payload: {
                        text: "Space removed successfully",
                        type: "success",
                    },
                });
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    dispatch({
                        type: "SET_UNAUTHORIZED",
                    });
                    return;
                }
                dispatch({
                    type: "SET_POPUP",
                    payload: {
                        text: err.response.data.error
                            ? err.response.data.error
                            : "Something Went Wrong. Please Try Again Later",
                        type: "error",
                    },
                });
                setTimeout(() => {
                    dispatch({
                        type: "SET_POPUP",
                        payload: {
                            text: "",
                            type: "",
                        },
                    });
                }, popupTimer);
            });
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleRemove = (id) => {
        let url = API_URL + `/spaces/${id}/remove-item`;
        let body = { "rate_id": id }
        axios
            .post(
                url,
                body
            ).then((resp) => {
                dispatch({
                    type: "SET_POPUP",
                    payload: {
                        text: "Item removed",
                        type: "success",
                    },
                })
                getRatesRequest()

            }).catch((err) => {
                if (err.response.status === 401) {
                    dispatch({
                        type: "SET_UNAUTHORIZED",
                    });
                    return;
                }
                dispatch({
                    type: "SET_POPUP",
                    payload: {
                        text: err.response.data.error
                            ? err.response.data.error
                            : "Something Went Wrong. Please Try Again Later",
                        type: "error",
                    },
                });

            });
        setTimeout(() => {
            dispatch({
                type: "SET_POPUP",
                payload: {
                    text: "",
                    type: "",
                },
            });
        }, popupTimer);
    }
    const showComingSoonMsg = () => {
        dispatch({
            type: "SET_POPUP",
            payload: {
                text: "Coming Soon",
                type: "success",
            },
        });
        setTimeout(() => {
            dispatch({
                type: "SET_POPUP",
                payload: {
                    text: "",
                    type: "",
                },
            });
        }, popupTimer);
    };
    const performAiAction = (inputCommand) => {
        let url = API_URL + `/rates/ai`;
        // setSummaryDialog(true)
        
        axios
            .post(
                url,
                { space_id: id, command: inputCommand }
            ).then((resp) => {
                console.log(resp.data.summary)
                let updatedSummary = summary + '\n' + resp.data.summary
                setSummary(updatedSummary);
                setSummaryDialog(true)
                // setOpenAiDialog(false)

            })
    }
    const setTags = (value) => {
        setSpaceKeywords([...value]);
        setSpaceFilterKeywords([...value]);
        let url = API_URL + `/spaces/${id}`;
        axios
            .put(
                url,
                { keywords: value }
            )
            .then((res) => {

                dispatch({
                    type: "SET_POPUP",
                    payload: {
                        text: "Keywords Updated Successfully",
                        type: "success",
                    },
                });
                setTimeout(() => {
                    dispatch({
                        type: "SET_POPUP",
                        payload: {
                            text: "",
                            type: "",
                        },
                    });
                }, popupTimer);
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    dispatch({
                        type: "SET_UNAUTHORIZED",
                    });
                    return;
                }
                dispatch({
                    type: "SET_POPUP",
                    payload: {
                        text: err.response.data.error
                            ? err.response.data.error
                            : "Something Went Wrong. Please Try Again Later",
                        type: "error",
                    },
                });
                setTimeout(() => {
                    dispatch({
                        type: "SET_POPUP",
                        payload: {
                            text: "",
                            type: "",
                        },
                    });
                }, popupTimer);
            });
    };
    if (!token)
        return (
            <Box>
                <Grid
                    container
                    spacing={2}
                    style={{ paddingTop: "34px", width: "100%" }}
                >
                    <Grid item xs={12} md={12} lg={11}>
                        <h2>Space</h2>
                        <h4 className="textMuted">
                            Please <a href="/login">sign-in</a> to view your Space
                        </h4>
                    </Grid>
                </Grid>
            </Box>
        );
    return (
        <Grid container spacing={2} style={{ paddingTop: "34px" }}>
            {socialCard ? (
                <Card className="cardIntegration">
                    <Button
                        onClick={(e) => setSocialCard(false)}
                        color="primary"
                        sx={{ position: "absolute", right: "0" }}
                    >
                        X
                    </Button>
                    <Typography
                        gutterBottom
                        variant="h5"
                        sx={{
                            fontSize: "22px",
                            textAlign: "center",
                            fontWeight: "500",
                            marginTop: "30px",
                            marginBottom: "32px",
                        }}
                        component="div"
                    >
                        Integrations
                    </Typography>
                    {[
                        { label: "Jira", img: social1, connected: true },
                        { label: "Monday", img: social2, connected: true },
                        { label: "Slack", img: social3, connected: false },
                        { label: "Trello", img: social4, connected: false },
                        { label: "Asana", img: social5, connected: false },
                    ].map((item, index) => (
                        <Box
                            sx={{
                                display: "flex",
                                marginBottom: "24px",
                                padding: "0px 30px",
                            }}
                            key={index}
                        >
                            <Avatar sx={{ width: "34px", height: "34px" }} src={item.img} />
                            <Typography
                                gutterBottom
                                variant="h5"
                                sx={{
                                    fontSize: "18px",
                                    textAlign: "center",
                                    fontWeight: "500",
                                    marginLeft: "14px",
                                    marginTop: "auto",
                                    marginBottom: "auto",
                                }}
                                component="div"
                            >
                                {item.label}
                            </Typography>
                            <Button
                                color="primary"
                                sx={{ marginLeft: "auto" }}
                                className={
                                    item.connected ? "connectedButton" : "connectButton2"
                                }
                                variant="contained"
                                disabled={item.connected}
                                onClick={() => showComingSoonMsg()}
                            >
                                {item.connected ? "Connected" : "Connect"}
                            </Button>
                        </Box>
                    ))}
                </Card>
            ) : null}
            <Grid item xs={12} md={12} lg={11} sx={{ paddingRight: "15px" }}>

                <div style={{ marginTop: "24px" }}>
                    <Typography
                        variant="h2"
                        component="h2"
                        style={{ fontSize: "22px", fontWeight: "500", marginBottom: "24px" }}
                    >
                        Space Bookmarks
                    </Typography>
                    <div style={{ display: 'flex', alignItems: 'center' }}>

                        <FormControl className="searchbar" fullWidth variant="standard">
                            <InputLabel htmlFor="standard-adornment-password">Search</InputLabel>

                            <Input
                                fullWidth
                                onKeyDown={onTagAdded}
                                className="searchBox"
                                id="standard-adornment-password"
                                value={searchItem}
                                onChange={(e) => setSearchItem(e.target.value)}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <img src={ai} style={{ width: '24px', marginLeft: '8px', cursor: 'pointer' }} alt="icon" onClick={() => { setSummaryDialog(true) }} />
                    </div>
                    <ChipList list={inputSearchTags} onDelete={handleSearchTagDelete} />

                    <Grid container spacing={2} style={{ paddingTop: "34px", width: "100%" }}
                    >
                        {data.rates && data.rates.map((m, index) => (
                            <Grid item xs={12} sm={2} md={4} key={index}>
                                <div style={{ height: '400px' }}>

                                    <HomeCardItem
                                        isBookmark={true}
                                        displayRemoveFromSpace={true}
                                        onRemoveFromSpaceClick={handleRemove}
                                        key={index}
                                        data={m}
                                        searhTags={[]}
                                        applyMargin={true}
                                        onRateSuccessfully
                                    />
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                </div>

            </Grid>

            <CreateSpace
                open={spacePopup}
                handleClose={handleClose}
                setSpaceAdded={setSpaceAdded}
            />

            <CollaboratorsDialog open={collobartorsDialogOpen}
                onClose={() => {
                    dispatch({
                        type: "SET_COLLABORATORS_DIALOG_OPEN",
                        payload: {
                            collobartorsDialogOpen: false,
                        },
                    });
                }}
                onAddCollaboratorClick={addCollaborator}
                onDeleteCollaboratorClick={deleteCollaborators}
                collaborators={collaborators}
            />
            <ConfirmationDialog
                open={deleteSpaceDialogOpen}
                onClose={() => {
                    dispatch({
                        type: "SET_DELETE_SPACE_DIALOG",
                        payload: {
                            deleteSpaceDialogOpen: false,
                        },
                    });
                }}
                onConfirm={() => {
                    deleteSpace()
                    dispatch({
                        type: "SET_DELETE_SPACE_DIALOG",
                        payload: {
                            deleteSpaceDialogOpen: false,
                        },
                    });

                }}
                title={"Delete rate"}
                cancelButtonString={"Cancel"}
                confirmButtonString={"Delete"}
                message={"Are you sure you want to delete this space?"}
            />
            {/* <AiDialog open={openAiDialog} onClose={() => { setOpenAiDialog(false) }} /> */}
            <AiSummaryEditor open={summaryDialogOpen}
                onPerform={performAiAction}
                onClose={() => { 
                    setSummaryDialog(false)
                    setSummary('');


                 }}
                defaultValue={"Hey how can i help you?"}
                value={summary}
            />

        </Grid>
    );
};
export default Space;
