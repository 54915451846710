import { Visibility, VisibilityOff } from "@mui/icons-material";
import StarsIcon from "@mui/icons-material/Stars";
import {
  Avatar,
  Button,
  CardActions, Dialog, DialogActions, DialogContent, DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Link,
  TextField,
} from "@mui/material";
import Card from "@mui/material/Card";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import React, { useState, useEffect } from "react";
import apple from "../../Assets/apple.png";
import facebook from "../../Assets/facebook.png";
import google from "../../Assets/google.png";
import "../style.css";
import SignUp from "./Signup";
import axios from "axios";
import { useDispatch } from "react-redux";
import { API_URL } from "../../consts";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import parse from 'html-react-parser';
const Login = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [form, setForm] = useState({
    email: "",
    showPassword: false,
    password: "",
  });
  


  const [formError, setFormError] = useState("");
  const [errObj, setErrObj] = useState({ email: "" });

  const [emailValidated, setEmailValidated] = useState(true);
  const [continue_, setContinue_] = useState(false);

  const handleFormChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const handleEnterEvent = (e) => {
    if (e.key === "Enter") {
      signIn();
    }
  };
  const handleEnterEventEmail = (e) => {
    if (e.key === "Enter") {
      continueWithEmail();
    }
  };
  const validateEmail = (email) => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    return false;
  };
  const continueWithEmail = () => {
    let val = validateEmail(form.email);
    setEmailValidated(val);
    setContinue_(true);
    setErrObj({ email: !val });
  };

  const handleClickShowPassword = () => {
    setForm({
      ...form,
      showPassword: !form.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

const signInWithGoogle = async () => {
  try {
    console.log('Initiating Google sign-in');
    
    // Trigger the federated sign-in
    await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
    console.log('done')
    // Once the sign-in is complete, retrieve the current session
    const session = await Auth.currentSession();
    console.log(session)
    const accessToken = session.getAccessToken().getJwtToken();
    console.log('Access Token:', accessToken);
    
    // Set the access token as the default authorization header for axios
    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
    
    // Optionally, you can perform additional actions, like API calls
    const url = API_URL + `/profile`;
    axios
    .get(url)
    .then((res) => {
      let user = {...res.data['profile']}
      user['token'] = accessToken
      axios.interceptors.response.use(
        function (resp) {
          return resp;
        },
        (resp) => {
          if (resp.response.status === 401) {
            dispatch({
              type: "SET_UNAUTHORIZED",
              payload: null,
            });
          }
          return Promise.reject(resp);
        }
      );
      res.data.profile['token'] =accessToken

      dispatch({
        type: "SET_USER_DATA",
        payload: res.data.profile,
        token: accessToken
      });
      // axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.login_response.token;

      setFormError("");
 
    })
    .catch((err) => {
      dispatch({
        type: "SET_UNAUTHORIZED",
      });
    });
    
  } catch (error) {
    console.error('Error during Google sign-in:', error);
    // setFormError("Failed to sign in with Google. Please try again.");
  }
};


  const signInWithFacebook = () => {
    Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Facebook,
    });
  };

  const signIn = () => {
    console.log('sign')
    let { email, password } = form;
    try {
      const user = Auth.signIn(form.email, form.password)
        .then((res) => {
          Auth.currentSession().then((data) => {
            let accessT = data.accessToken.getJwtToken();
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + accessT;
            setFormError("");

            let url = API_URL + `/profile`;

            //TODO no need to post to backend

          });
        })
        .catch((error) => {
          setFormError(error.message);
        });
    } catch (error) {
      //TODO handle different erros and show to users
      setFormError(error.message);
    }
  };

  const [signUp, setSignUp] = useState(false);
  const [termsAndConditionsOpen, setTermsAndConditionsDialogStatus] = useState(false);

  const [terms, setTerms] = useState('');
  const handleOpenTermsAndConditions = () => {
    setTermsAndConditionsDialogStatus(true);
    fetch(API_URL+ '/security/terms-and-conditions')
        .then(response => response.text())
        .then(data => {
          setTerms(data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
  };

  const handleSignUp = () => {
    setFormError("");
    setSignUp(true);
  };


  console.log('login')
  return (
    <>
      {signUp?<SignUp setSignUp={setSignUp}
                     onClickTermsAndConditions={()=>handleOpenTermsAndConditions()}/>
          :<div>

            <Grid container>
              <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "5px 20px",
                  }}
              >

                <Typography variant="h6" className="dataRateHeader">
                <img src={`${process.env.PUBLIC_URL}/Datarate_logo_new.svg`} style={{ height: '70px' }} alt="Datarate Logo" />
                
                </Typography>
              </Grid>
            </Grid>
            <Grid container style={{ justifyContent: "center" }}>
              <Grid item className="loginCard">
                {!!formError ? <Alert severity="error">{formError}</Alert> : null}
                <Card style={{ padding: "30px", borderRadius: "15px" }}>
                  <div className="signInHeader">
                    <h2 className="signIn"> Sign In</h2>
                    <Link
                        className="dont_have_account"
                        component="button"
                        color="primary"
                        onClick={handleSignUp}
                    >
                      I don't have an account
                    </Link>
                  </div>
                  <TextField
                      name={"email"}
                      hiddenLabel
                      fullWidth
                      className="loginTextfields"
                      id="standard-basic"
                      label={"Email"}
                      value={form.email}
                      onChange={handleFormChange}
                      onKeyDown={handleEnterEventEmail}
                      variant="standard"
                      error={Boolean(errObj["email"])}
                      helperText={
                        !emailValidated
                            ? !form.email.length
                                ? "Required"
                                : errObj["email"]
                                    ? "Invalid Email Address"
                                    : ""
                            : ""
                      }
                  />

                  {continue_ && emailValidated && form.email.length ? (
                      <FormControl
                          className="loginTextfields"
                          fullWidth
                          variant="standard"
                      >
                        <InputLabel htmlFor="standard-adornment-password">
                          Password
                        </InputLabel>
                        <Input
                            onKeyDown={handleEnterEvent}
                            id="standard-adornment-password"
                            type={form.showPassword ? "text" : "password"}
                            value={form.password}
                            fullWidth
                            name="password"
                            onChange={handleFormChange}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                  {form.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            }
                        />
                      </FormControl>
                  ) : (
                      <></>
                  )}

                  {!(continue_ && emailValidated && form.email.length) ? (
                      <Button
                          className="connectButton"
                          variant="contained"
                          fullWidth
                          onClick={() => {
                            continueWithEmail();
                          }}
                      >
                        Continue
                      </Button>
                  ) : (
                      <Button
                          className="connectButton"
                          variant="contained"
                          fullWidth
                          onClick={() => {
                            signIn();
                          }}
                      >
                        Sign in
                      </Button>
                  )}
                  <Link className="cant_signIn" component="button">
                    Can't Sign In?{" "}
                  </Link>
                  <Link className="signin_with" component="button">
                    OR SIGN IN WITH
                  </Link>
                  <Grid container spacing={2}>
                    <Grid textAlign="center" item xl={6} lg={4} md={12} xs={12}>
                      {/*<Button*/}
                      {/*  className="loginButtons"*/}
                      {/*  variant="outlined"*/}
                      {/*  startIcon={*/}
                      {/*    <Avatar*/}
                      {/*      sx={{ height: "20px", width: "20px" }}*/}
                      {/*      src={facebook}*/}
                      {/*    />*/}
                      {/*  }*/}
                      {/*  onClick={() => {*/}
                      {/*    signInWithFacebook();*/}
                      {/*  }}*/}
                      {/*>*/}
                      {/*  FACEBOOK*/}
                      {/*</Button>*/}
                    </Grid>
                    <Grid textAlign="center" item xl={6} lg={4} md={12} xs={12}>
                      <Button
                          className="loginButtons"
                          variant="outlined"
                          startIcon={
                            <Avatar
                                sx={{ height: "20px", width: "20px" }}
                                src={google}
                            />
                          }
                          onClick={() => {
                            signInWithGoogle();
                          }}
                      >
                        GOOGLE
                      </Button>
                    </Grid>
                    {/*<Grid textAlign="center" item xl={4} lg={4} md={12} xs={12}>*/}
                    {/*  <Button*/}
                    {/*    className="loginButtons"*/}
                    {/*    variant="outlined"*/}
                    {/*    startIcon={*/}
                    {/*      <Avatar*/}
                    {/*        sx={{ height: "20px", width: "20px" }}*/}
                    {/*        src={apple}*/}
                    {/*      />*/}
                    {/*    }*/}
                    {/*  >*/}
                    {/*    APPLE*/}
                    {/*  </Button>*/}
                    {/*</Grid>*/}
                  </Grid>
                  <div>
                    <p className="terms-point">
                      By signing up, you agree to the
                      <Link className="terms_conds" component="button"
                            onClick={()=>handleOpenTermsAndConditions()}

                      >
                        Terms and Conditions
                      </Link>
                      and
                      <Link className="terms_conds" component="button"
                            onClick={()=>handleOpenTermsAndConditions()}

                      >
                        Privacy Policy
                      </Link>
                    </p>
                  </div>

                  <CardActions></CardActions>
                </Card>
              </Grid>

              {/* <Grid item xs={4}></Grid> */}
            </Grid>
          </div>}
      <Dialog open={termsAndConditionsOpen} onClose={()=>setTermsAndConditionsDialogStatus(false)}>
        <DialogTitle>Terms and Conditions</DialogTitle>
        <DialogContent dividers>

          {parse(terms)}
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>setTermsAndConditionsDialogStatus(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
};

export default Login;
