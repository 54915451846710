import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
import BusinessAccount from "./BusinessAccount/BusinessAccount";
import Reviews from "./Home/Reviews";
import MyReviews from "./Home/MyReviews";
import Plans from "./Plans/PlansComponent";
import CommunityRates from "./Home/CommunityRates";
import Profile from "./Profile/Profile";
import Bookmarks from "./Bookmarks/Bookmarks";
import Login from "./Login/Login";
import TrendsAndAnalytics from "./analytics/TrendsAndAnalytics";
import WebsiteDetails from "./WebsiteDetails/WebsiteDetails";
import OthersProfile from "./Profile/OthersProfile";
import Space from "./Space/Space";
import MySpaces from "./Space/MySpaces";
import Reports from "./Reports/Reports";
import ExtensionLoginSuccess from "./Extension/ExtensionLoginSuccess";
import { useDispatch } from "react-redux";
import axios from "axios";
import { API_URL } from "../consts";
import { Auth, Hub } from "aws-amplify";

const Router = ({ authorized, setIsLocationAuth, user }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  let navigate = useNavigate();
  const [userAuthorized, setUserAuthorized] = useState(
    !!localStorage.getItem("user_token")
  );

  const sendTokensToExtension = (accessToken, refreshToken) => {
    window.postMessage(
      {
        action: "sendTokens",
        accessToken: accessToken,
        refreshToken: refreshToken,
      },
      "*"
    );
  };

  const checkUserSession = async () => {
    console.log('check user session')
    try {
      const session = await Auth.currentSession();
      const accessToken = session.getAccessToken().getJwtToken();
      const refreshToken = session.getRefreshToken().getToken();

      sendTokensToExtension(accessToken, refreshToken);
      localStorage.setItem("user_datarate", accessToken);

      // Update the user state
      setUserAuthorized(true);

      // Set the access token as the default authorization header for axios
      getProfile(accessToken);

      dispatch({
        type: "SET_USER_DATA",
        payload: { token: accessToken },
      });
    } catch (error) {
      console.error("Error retrieving session after redirect:", error);
      setUserAuthorized(false);
    }
  };

  useEffect(() => {
    // Listen for auth events to ensure the user session is properly restored
    const listener = (data) => {
      if (data.payload.event === "signIn" || data.payload.event === "cognitoHostedUI") {
        checkUserSession(); // Call the session check when the user is signed in
      }
    };

    Hub.listen("auth", listener);

    // // Initial session check with a delay to allow session restoration
    // setTimeout(() => {
    //   checkUserSession();
    // }, 1000); // Adjust delay as needed

    setIsLocationAuth(location.pathname === "/login");

    return () => {
      Hub.remove("auth", listener);
    };
  }, [location.pathname]);

  const getProfile = (token) => {
    dispatch({ type: "SET_TOKEN", payload: token });

    let url = `${API_URL}/profile`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: "SET_USER_DATA",
          payload: { ...res.data.profile },
        });
      })
      .catch((error) => {
        console.error("Error fetching user profile:", error);
        dispatch({
          type: "SET_UNAUTHORIZED",
        });
      });
  };

  return (
    <Box component="main" className={authorized ? "mainBox" : "mainBox2"}>
      <Routes>
        <Route path="/" element={userAuthorized?<MyReviews />:<CommunityRates />} exact />
        <Route path="/profile" element={<Profile />} exact />
        <Route path="/trends" element={<TrendsAndAnalytics />} exact />
        <Route path="/plans" element={<Plans />} exact />
        <Route path="/my-reviews" element={<MyReviews />} exact />
        <Route path="/community" element={<CommunityRates />} exact />

        <Route path="/add-website" element={<BusinessAccount />} exact />
        <Route path="/add-space" element={<BusinessAccount />} exact />
        <Route
          path="/my-websites"
          element={
            userAuthorized ? <Reports /> : <Navigate to="/add-website" />
          }
          exact
        />
        <Route path="/login" element={<Login />} exact />
        <Route path="/my-spaces" element={<Space />} exact />
        <Route path="/website/:id" element={<WebsiteDetails />} exact />
        <Route path="/spaces/:id" element={<Space />} exact />
        <Route path="/user/:id" element={<OthersProfile />} exact />
        <Route
          path="/extension-login-success"
          element={<ExtensionLoginSuccess />}
          exact
        />
      </Routes>
    </Box>
  );
};

export default Router;
